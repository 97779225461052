// ---------------------------------------
// Colors
// Most colors should be defined in _tailwind_colors.scss
// ---------------------------------------
$red: #ce3d0d;
$amber: #ff9e1f;
$green-ui: #0ba04b;
$link-color: tw-color('primary', 'accent');

// google
$google-blue-lightest: #e7effe;
$google-blue-lighter: #6cbce7;
$google-blue: #216fee;
$google-blue-darker: #1060e2;

// ---------------------------------------
// Foreground utility classes
// ---------------------------------------

.red {
  color: $red;
  background: none !important; // overrides third-party spell checker .red coming from
  // https://wsc.ilclassroomcdn.com/spellcheck3/lf/scayt3/ckscayt/css/scayt.css
}

.amber {
  color: $amber;
}

.green-ui {
  color: $green-ui;
}

.link-color {
  color: $link-color;
}

// ---------------------------------------
// Background utility classes
// ---------------------------------------

.bg-red {
  background-color: $red;
}

.bg-amber {
  background-color: $amber;
}

.bg-green-ui {
  background-color: $green-ui;
}

.google-blue {
  color: $google-blue;
}

.google-blue-dark {
  color: $google-blue-darker;
}

.bg-google-blue {
  background-color: $google-blue-lighter;
}

.bg-google-blue-light {
  background-color: $google-blue-lightest;
}
