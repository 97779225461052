.btn {
  @apply bg-focus text-primary hover:text-accent border-base inline-flex items-center justify-center gap-1 rounded-lg border px-3 py-2 transition-colors;
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: $font-default;
  font-size: $fs-m;
  font-weight: normal;
  line-height: normal;
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0 4px 0 rgb(var(--primary-200));

  @include breakpoint(medium up) {
    white-space: nowrap;
  }

  &.no-shadow {
    box-shadow: none !important;
    &:hover,
    &:focus {
      box-shadow: none !important;
    }
  }

  &:hover {
    @apply text-accent bg-focus-hover border-primary-accent;
    box-shadow: 0 2px 0 rgb(var(--primary-accent));
  }

  &:focus {
    @apply text-accent-hover bg-focus-hover border-base-hover;
  }

  &.caution {
    @apply bg-focus;
    border-color: lighten($red, 30%);
    color: $red;
    box-shadow: 0 4px 0 rgba(var(--red-500), 0.25);

    &:hover {
      border-color: $red;
      color: darken($red, 10%);
      box-shadow: 0 2px 0 rgba(var(--red-500), 0.25);

      span svg {
        color: darken($red, 10%);
      }
    }

    &:focus {
      color: darken($red, 10%);
    }
  }

  .icon.right {
    margin-left: 0.2rem;
  }
}

.btn--neutral-hover:hover {
  @apply border-neutral-300;
  box-shadow: 0 4px 0 tw-color('neutral', 600);
}

// disabled state
.btn.disabled,
a.disabled,
input:disabled,
button:disabled,
select:disabled,
li.disabled,
.toggle-btn > label > .toggle-btn__option.disabled {
  @apply bg-neutral-50 text-muted border-neutral-100 text-opacity-80;
  cursor: not-allowed;
  box-shadow: none;

  &:hover,
  &:focus,
  &.btn--green:hover,
  &.btn--green:focus {
    // Learnosity injects CSS, including directives for .btn--green, that necessitates this nonsense
    @apply border-neutral-50;
    background-color: tw-color('neutral', 50) !important;
    color: tw-color('neutral', 700);
    box-shadow: none;
  }
}

// -----------------
// Button sizes
// -----------------

.btn--small {
  font-size: $fs-s;
  padding: 0.375rem 0.625rem;
}

.btn--big {
  @apply px-5 py-3.5;
  font-size: $fs-l;
}

.btn--xlarge {
  font-size: $fs-xl;
  padding: 0.75rem 2.5rem;
}

// -----------------
// Button types
// -----------------

// Block (full width of container)
.btn--block {
  display: block;
}

.btn--primary {
  @apply bg-accent text-inverted border-inverted;
  box-shadow: 0 4px 0 tw-color('primary', 600);

  &:hover,
  &:focus {
    @apply bg-accent-hover text-inverted-hover border-inverted-hover;
    box-shadow: 0 2px 0 tw-color('primary', 600);
  }

  &.caution {
    @apply bg-red text-inverted border-red-600;
    box-shadow: 0 4px 0 tw-color('red', 700);
    &:hover,
    &:focus {
      @apply text-inverted-hover border-red-800 bg-red-600;
      box-shadow: 0 2px 0 tw-color('red', 800);
    }
  }
}

// button styled like a link
.btn--demoted {
  @apply text-accent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;

  &:hover,
  &:focus {
    @apply text-accent-hover;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &[disabled] {
    @apply text-gray-700;
    background-color: transparent;
  }

  &.caution {
    background-color: transparent;
    border-color: transparent;
    color: $red;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      color: darken($red, 10%);
      box-shadow: none;
    }
  }

  &.small {
    font-size: $fs-s;
  }
}

.btn--pill {
  @apply text-important bg-focus border-inverted;
  border-radius: 0.5rem;
  border: lighten(tw-color('gray', 400), 10%) $border-width solid;
  cursor: pointer;
  font-size: 0.825rem;
  padding: 0.25rem 0.5rem;
  transition: background 0.3s ease;

  &:hover {
    @apply bg-inverted text-inverted border-base;

    .icon {
      @apply text-important;
    }
  }
}

.btn--blue {
  background-color: darken(tw-color('blue', 500), 10%);
  border-color: darken(tw-color('blue', 500), 15%);
  @apply text-white;

  &:hover,
  &:focus {
    background-color: darken(tw-color('blue', 500), 20%);
    border-color: darken(tw-color('blue', 500), 25%);
    color: white;
  }
}

.btn--green,
.btn--secondary {
  @apply bg-secondary border-secondary hover:bg-secondary-hover text-base;
  box-shadow: 0 4px 0 tw-color('green', 600);
  line-height: normal;

  &:hover,
  &:focus {
    @apply bg-secondary-hover text-base-hover border-secondary-hover;
    box-shadow: 0 2px 0 tw-color('green', 800);
    svg,
    > span svg {
      @apply text-neutral-900;
    }
  }
}

// assignment reports score buttons
.btn--score {
  padding: 8px 2rem;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;

  .score-dot {
    position: static;
    flex-shrink: 0;
  }

  .icon {
    position: absolute;
    right: 6px;
  }
}

// Clever login
.btn--clever {
  @apply border-transparent text-white;
  background-color: #2c65ad;
  box-shadow: 0 4px 0 rgb(var(--blue-100));

  &:hover,
  &:focus {
    @apply border-transparent text-white;
    background-color: darken(#2c65ad, 10%);
    box-shadow: 0 2px 0 rgb(var(--blue-accent));
  }

  svg {
    bottom: 2px;
    position: relative;
  }
}

// add/remove functionality within tables
.btn--remove,
.btn--add {
  @apply bg-inverted text-inverted;
  border-radius: 50%;
  cursor: pointer;
  margin-right: $sp-xs;
  padding: 0.15rem 0.25rem;
  transition: all 0.2s;

  .icon-s {
    height: 0.8rem;
    width: 0.8rem;
  }

  svg {
    margin: 0;
    width: 0.8rem;
  }

  &:hover,
  &:active {
    @apply bg-inverted-hover;
  }
}

.btn--add {
  @apply bg-inverted;
  height: 1.5rem;
  width: 1.5rem;

  &:hover,
  &:active {
    @apply bg-inverted-hover;
  }

  svg {
    margin-left: -1px;
  }
}

// split buttons (for one primary action with other related actions in a dropdown)
.btn--split {
  padding: 0;
}

.btn__split,
.btn__split-left,
.btn__split-right {
  @apply border-base border;
  display: inline-block;
  padding: 7px 0.75rem;

  &:first-child {
    border: 0;
  }

  a {
    @apply text-base;
  }
}

.btn__split-right {
  padding: 7px;
}

// Bookmark button
.save-to-list {
  @apply cursor-pointer  transition-colors;
  min-width: 1rem;

  .bookmarked {
    @apply h-0 stroke-0 transition-all duration-500;
    fill: transparent;
  }

  .bookmark-remove {
    @apply text-red-accent opacity-0;
  }

  .bookmark,
  .bookmark-remove {
    @apply transition-opacity duration-300 ease-out;
  }

  &.save-to-list--checked {
    @apply text-green-600;

    svg {
      @apply stroke-current;
    }

    .bookmarked {
      @apply h-full;
      fill: currentColor;
    }

    /** Hides to only show the remove button **/
    .bookmark {
      @apply opacity-0;
    }

    .bookmark-remove {
      @apply opacity-100;
    }
  }
  &:hover {
    .bookmark {
      @apply opacity-0;
    }

    .bookmark-remove {
      @apply outline-none opacity-100;
    }
  }
  svg {
    @apply transition-all;
  }
}
